import {AreaSeries, DiscreteColorLegend, HorizontalGridLines, XAxis, XYPlot, YAxis} from 'react-vis';
import moment from 'moment';
import {cleanInfinity} from './utils';

// KEEP IN SYNC:
// ui/src/LoadsComparisonGraph.js
// server/src/LoadsComparisonGraph.js

// don't use external CSS classes, this is server side rendered
export default function LoadsComparisonGraph({audit}) {
	if (!audit.energyAssessment?.length) {
		return 'Enter Assessment Data';
	}

	if (!audit.electricityBills?.length) {
		return 'Enter Electricity Bills';
	}

	const devicePercentages = getDevicePercentages(audit.deviceTypes, audit.energyAssessment);
	const linesData = getElectricityBillsData(audit.deviceTypes, audit.electricityBills, devicePercentages);

	const lines = getChartLines(linesData);

	const legend = audit.deviceTypes.map(({name}) => {
		return {
			title: name,
		};
	});

	const date = moment(audit.electricityBills[0].startDate).date(1);

	const chartWidth = 550;

	const renderLineChart = (
		<div style={{position: 'relative'}}>
			<XYPlot width={chartWidth} height={300} stackBy="y">
				<HorizontalGridLines />
				{lines}
				<XAxis title="Month" tickFormat={v => date.clone().add(v, 'months').format('MMM')} />
				<YAxis title="kWh" />
			</XYPlot>
			<DiscreteColorLegend items={legend} style={{position: 'absolute', top: 0, left: chartWidth}} />
		</div>
	);

	return (
		renderLineChart
	);
}

function getElectricityBillsData(deviceTypes, electricityBills, devicePercentages) {
	const currentBill = (electricityBills || []).reduce((current, bill) => {
		return (!current || current.startDate < bill.startDate) ? bill : current;
	}, null);

	return deviceTypes.map(({short}) => {
		return currentBill.months.map(({kWh}) => {
			return (kWh || 0) * (devicePercentages[short] || 0);
		});
	});
}

function getChartLines(linesData) {

	return linesData.map((lineData, index) => {
		const data = lineData.map((y, x) => ({x, y}));

		return <AreaSeries key={index} data={data} />;
	});
}

function getDevicePercentages(deviceTypes, energyAssessment) {
	const result = {};

	let totalOldKWhWk = 0;

	energyAssessment.forEach(eai => {
		const oldKWhWk = (eai.quantity * (eai.wh / 1000) * eai.opDaysWk * eai.hrsDay) || null;

		totalOldKWhWk += oldKWhWk;

		if (result[eai.deviceType] === undefined) {
			result[eai.deviceType] = oldKWhWk;
		} else {
			result[eai.deviceType] += oldKWhWk;
		}
	}, 0);

	for (const deviceType in result) {
		result[deviceType] = cleanInfinity(result[deviceType] / totalOldKWhWk);
	}

	return result;
}
