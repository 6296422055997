import 'react-vis/dist/style.css';
import YearlyKWhGraph from './YearlyKWhGraph';
import LoadsComparisonGraph from './LoadsComparisonGraph';

export default function EnergySummary({audit}) {
	return <>
			<YearlyKWhGraph audit={audit} />
			<LoadsComparisonGraph audit={audit} />
		</>;
}
