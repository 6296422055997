import {memo} from 'react';
import TableEdit, {TableEditRowActions} from './TableEdit';
import {FieldEditor, SelectField, TextAreaField} from './EditFields';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {EDIT_TYPE_LIST_VALUE, EDIT_TYPE_OPTIONS} from './editTypes';

const defaultEntry = {question: '', type: 'Text', answer: ''};

export default function AuditEditBaseline({isStructureEdit}) {
	return <TableEdit
		name="baseline"
		rowComponent={BaselineRow}
		isStructureEdit={isStructureEdit}
		defaultEntry={defaultEntry}
		headerComponent={BaselineHeader}
	/>;
}

const BaselineHeader = memo(({isStructureEdit}) => {
	if (isStructureEdit) {
		return <Row>
			<Col md={6}><span className="font-weight-bold">Question</span></Col>
			<Col md={2}><span className="font-weight-bold">Type</span></Col>
			<Col md={3}><span className="font-weight-bold" /></Col>
			<Col md={1}><span className="font-weight-bold">Actions</span></Col>
		</Row>;
	}

	return <Row>
		<Col md={6}><span className="font-weight-bold">Question</span></Col>
		<Col md={6}><span className="font-weight-bold">Answer</span></Col>
	</Row>;
});

const BaselineRow = memo(({values, prefix, isStructureEdit, move, remove, index, totalLength}) => {

	if (isStructureEdit) {
		return <Row className="mt-1">
			<Col md={6} className="d-flex"><div style={{minWidth: 5 + (10 * String(totalLength).length)}}>{index + 1}.</div><div className="w-100"><TextAreaField name={`${prefix}.question`} /></div></Col>
			<Col md={2}><SelectField name={`${prefix}.type`} options={EDIT_TYPE_OPTIONS} /></Col>
			<Col md={3}>{values.type === EDIT_TYPE_LIST_VALUE && <TextAreaField name={`${prefix}.list`} placeholder="List Entries" />}</Col>
			<Col md={1}><TableEditRowActions remove={remove} index={index} totalLength={totalLength} move={move} /></Col>
		</Row>;
	}

	return <Row className="mt-1">
		<Col md={6} className="d-flex"><div style={{minWidth: 5 + (10 * String(totalLength).length)}}>{index + 1}.</div><div className="w-100 ws-pre-wrap">{values.question}</div></Col>
		<Col md={6}><FieldEditor values={values} name={`${prefix}.answer`} /></Col>
	</Row>;
});
