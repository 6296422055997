import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {LinkContainer} from 'react-router-bootstrap';
import {Auth} from '@aws-amplify/auth';
import {AUTH_STATE_CHANGE_EVENT, AuthState, UI_AUTH_CHANNEL} from '@aws-amplify/ui-components';
import {Hub} from '@aws-amplify/core';
// import {AmplifySignOut} from '@aws-amplify/ui-react';

function AppMenu() {

	return (
		<Navbar bg="light" expand="sm">
			<LinkContainer to="/">
				<Navbar.Brand>EEA</Navbar.Brand>
			</LinkContainer>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="mr-auto">
					<LinkContainer to="/audits" className="mr-5"><Nav.Link>Audits</Nav.Link></LinkContainer>
					<Nav.Link href="https://ea.planzlink.com.au/help/LnMDabiwaxNU8zLzDLCzgGEYRQCtLXA3/index.html" target="_blank">Help</Nav.Link>
				</Nav>
			</Navbar.Collapse>
			<Navbar.Collapse className="justify-content-end">
				<Nav>
					{/*<AmplifySignOut/>*/}
					<Nav.Link onClick={async () => {
						await Auth.signOut();
						// trigger update for withAuthenticator to show login screen again
						// looks like a bug to me that it doesn't happen automatically
						Hub.dispatch(UI_AUTH_CHANNEL, {
							event: AUTH_STATE_CHANGE_EVENT,
							message: AuthState.SignedOut,
						});
					}}>Logout</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default function AppMenuContainer() {
	return <AppMenu />;
}
