import Form from 'react-bootstrap/Form';
import {FastField, getIn} from 'formik';
import FormikSelect from './FormikSelect';
import shallowEqual from './shallowEqual';
import {EDIT_TYPE_FILES, EDIT_TYPE_LIST_VALUE} from './editTypes';
import moize from 'moize';
import {FieldFileManager} from './FieldFileManager';
import TextareaAutosize from 'react-textarea-autosize';
import Datetime from 'react-datetime';
import moment, {isMoment} from 'moment';

export function TextField({name, label, ...props}) {
	return <>
		{!!label && <Form.Label>
			{label}
		</Form.Label>}
		<FastField name={name}>
			{({field, meta}) => (<>

				<Form.Control
					isValid={meta.touched && !meta.error}
					isInvalid={meta.touched && meta.error}
					autoComplete="nope"
					{...props}
					{...field}
				/>

				{meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
		</>)}
		</FastField>
	</>;
}

export function TextAreaField({name, label, ...props}) {
	return <>
		{!!label && <Form.Label>
			{label}
		</Form.Label>}
		<FastField name={name}>
			{({field, meta}) => (<>

				<Form.Control
					as={TextArea}
					isValid={meta.touched && !meta.error}
					isInvalid={meta.touched && meta.error}
					{...props}
					{...field}
				/>

				{meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
		</>)}
		</FastField>
	</>;
}

export function SelectField({name, label, ...props}) {
	return <>
		{!!label && <Form.Label>
			{label}
		</Form.Label>}
		<FastField name={name} shouldUpdate={shouldUpdate} {...props}>
			{({field, meta}) => (<>

				<FormikSelect
					isValid={meta.touched && !meta.error}
					isInvalid={meta.touched && meta.error}
					{...props}
					{...field}
					placeholder="Select..."
				/>

				{meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
		</>)}
		</FastField>
	</>;
}

function shouldUpdate({formik: formikNew, children: cn,  ...newProps}, {formik: formikOld, children: co, ...oldProps}) {
	if (!shallowEqual(newProps, oldProps)) {
		return true;
	}
	if (getIn(formikNew.values, newProps.name) !== getIn(formikOld.values, newProps.name) || getIn(formikNew.errors, newProps.name) !== getIn(formikOld.errors, newProps.name) || getIn(formikNew.touched, newProps.name) !== getIn(formikOld.touched, newProps.name) || formikNew.isSubmitting !== formikOld.isSubmitting) {
		return true;
	}

	return false;
}

export function FilesField({name, label, ...props}) {
	return <>
		{!!label && <Form.Label>
			{label}
		</Form.Label>}
		<FastField name={name}>
			{({field, meta, form: {values: {id}}}) => {
				return (<>

					<FieldFileManager
						name={name}
						value={field.value}
						onChange={field.onChange}
						auditID={id}
					/>

					{meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
				</>);
			}}
		</FastField>
	</>;
}

export function CheckboxField({name, label, ...props}) {
	return <>
		<FastField name={name}>
			{({field, meta}) => (<>

				<Form.Check
					id="AuditEditMainActive"
					type="checkbox"
					label={label}
					isValid={meta.touched && !meta.error}
					isInvalid={meta.touched && meta.error}
					{...props}
					{...field}
					value={true}
					checked={field.value}
				/>

				{meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
			</>)}
		</FastField>
	</>;
}

const getListOptions = moize.infinite(list => {
	return list?.split(/[\r\n]+/g).filter(x => x !== '').map(value => ({value, label: value})) || [];
});
export function FieldEditor({values: {type, list}, name}) {
	switch (type) {
		case EDIT_TYPE_LIST_VALUE:
			return <SelectField name={name} options={getListOptions(list)} isClearable />;
		case EDIT_TYPE_FILES:
			return <FilesField name={name} />;
		default:
			return <TextAreaField name={name} />;
	}
}

const verticalResize = {resize: 'vertical'};
function TextArea({isInvalid, isValid, ...props}) {
	return (
		<TextareaAutosize
			className={`${isInvalid ? 'is-invalid' : ''} ${isValid ? 'is-valid' : ''} form-control`}
			style={verticalResize}
			{...props}
		/>
	);
}

export function DateField({name, label, ...props}) {
	return <>
		{!!label && <Form.Label>
			{label}
		</Form.Label>}
		<FastField name={name}>
			{({field, meta}) => (<>

				<DatetimeWrapper
					isValid={meta.touched && !meta.error}
					isInvalid={meta.touched && meta.error}
					timeFormat={false}
					{...props}
					{...field}
				/>

				{meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
			</>)}
		</FastField>
	</>;
}

function DatetimeWrapper({
	className,
	onChange,
	onBlur,
	name,
	value,
	isInvalid,
	isValid,
	...props
}) {
	const onInternalChange = value => {
		value = isMoment(value) ? value.format('YYYY-MM-DD') : value;
		onChange({target: {value, name}});
	};

	const onInternalBlur = () => {
		onBlur({target: {name}});
	};

	return (
		<Datetime
			onChange={onInternalChange}
			value={value ? moment(value) : null}
			inputProps={{
				placeholder: 'dd/mm/yyyy',
				onBlur: onInternalBlur,
				className: `form-control ${isInvalid ? 'is-invalid' : ''} ${isValid ? 'is-valid' : ''}`,
			}}
			closeOnSelect
			{...props}
		/>
	);
}
