import {memo, useCallback} from 'react';
import {SelectField, TextAreaField, TextField} from './EditFields';
import {FieldArray, useFormikContext} from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import moize from 'moize';
import TrashIcon from 'react-bootstrap-icons/dist/icons/trash';
import {getTypeOptions} from './dataUtils';

const defaultEntry = {
	reference: '',
	area: '',
	deviceType: '',
	device: '',
	remarks: '',
	quantity: '1',
	wh: '',
	opDaysWk: '',
	hrsDay: '',
	opportunities: '',
	newWh: '',
};

const getAreaOptions = moize.infinite(areas => {
	return areas?.map(({short, name}) => ({value: short, label: name})) || [];
});

const getDeviceOptions = moize.infinite((devices, type) => {
	return devices?.filter(dev => dev.type === type).map(({name}) => ({value: name, label: name})) || [];
});

export default function AuditEditEnergyAssessment() {
	const {values: {deviceTypes, areas, devices}, setFieldValue} = useFormikContext();

	const areaOptions = getAreaOptions(areas);
	const deviceTypeOptions = getTypeOptions(deviceTypes);
	return <Container fluid className="h-100 overflow-auto flex-grow-1">
		{/*<Table size="sm" hover bordered>*/}
		{/*	<thead>*/}
		{/*		<tr>*/}
		{/*			<th>Building<br />Reference</th>*/}
		{/*			<th>Area</th>*/}
		{/*			<th>Type</th>*/}
		{/*			<th>Device<br />Name</th>*/}
		{/*			<th>Device</th>*/}
		{/*			<th>Remarks</th>*/}
		{/*			<th>Quantity</th>*/}
		{/*			<th>Avg Wh</th>*/}
		{/*			<th>Op. Days<br />/ wk</th>*/}
		{/*			<th>hrs / day</th>*/}
		{/*			<th>Watts new</th>*/}
		{/*			<th>Opportunities</th>*/}
		{/*		</tr>*/}
		{/*	</thead>*/}
		{/*	<tbody>*/}
				<FieldArray
					name="energyAssessment"
				>
					{({form, name, push, remove}) => {
						const rows = form.values.energyAssessment || [];
						return <>
							{rows.map((values, index) => {
								return <TableRow
									key={index}
									index={index}
									prefix={`${name}[${index}]`}
									values={values}
									areaOptions={areaOptions}
									deviceTypeOptions={deviceTypeOptions}
									devices={devices}
									remove={remove}
									setFieldValue={setFieldValue}
								/>
							})}

							<Row className="mt-3 mb-5">
								<Col md={2}>
									<Button onClick={() => push(defaultEntry)} block variant="outline-primary">Add More</Button>
								</Col>
							</Row>
						</>;
					}}
				</FieldArray>

			{/*</tbody>*/}
		{/*</Table>*/}
	</Container>;
}

const daysWeekOptions = [
	{value: 1, label: '1'},
	{value: 2, label: '2'},
	{value: 3, label: '3'},
	{value: 4, label: '4'},
	{value: 5, label: '5'},
	{value: 6, label: '6'},
	{value: 7, label: '7'},
];
const TableRow = memo(({values, prefix, areaOptions, deviceTypeOptions, devices, remove, index, setFieldValue}) => {
	const deviceOptions = getDeviceOptions(devices, values.deviceType);

	const setDeviceDefaults = useCallback(({target: {value}}) => {
		const device = devices.find(dev => dev.name === value);
		setFieldValue(`${prefix}.opportunities`, device?.opportunities || '');
		setFieldValue(`${prefix}.wh`, device?.wh || '');
	}, [devices, setFieldValue, prefix]);

	return (<>
		<Row className="mt-2">
			<Form.Label column md={1}>
				<strong>Building ref.</strong>
			</Form.Label>
			<Col md={2}>
				<TextField name={`${prefix}.reference`} />
			</Col>
			<Form.Label column md={1}>
				<strong>Area</strong>
			</Form.Label>
			<Col md={2}>
				<SelectField name={`${prefix}.area`} options={areaOptions} />
			</Col>
			<Col md={{offset: 5, span: 1}}>
				<Button
					title="Remove"
					variant="outline-danger"
					size="sm"
					onClick={() => remove(index)}
					block
				>
					<TrashIcon />
				</Button>
			</Col>
		</Row>
		<Row className="mt-2">
			<Form.Label column md={1}>
				<strong>Type</strong>
			</Form.Label>
			<Col md={2}>
				<SelectField name={`${prefix}.deviceType`} options={deviceTypeOptions} />
			</Col>
			<Form.Label column md={1}>
				<strong>Device</strong>
			</Form.Label>
			<Col md={5}>
				<SelectField
					name={`${prefix}.device`}
					options={deviceOptions}
					isClearable
					isDisabled={!deviceOptions.length}
					onSelect={setDeviceDefaults}
				/>
			</Col>
			<Form.Label column md={1}>
				<strong>Remarks</strong>
			</Form.Label>
			<Col md={2}>
				<TextAreaField name={`${prefix}.remarks`} />
			</Col>
		</Row>
		<Row className="mt-2">
			<Form.Label column md={1}>
				<strong>Quantity</strong>
			</Form.Label>
			<Col md={2}>
				<TextField name={`${prefix}.quantity`} />
			</Col>
			<Form.Label column md={1}>
				<strong>Avg Wh</strong>
			</Form.Label>
			<Col md={2}>
				<TextField name={`${prefix}.wh`} />
			</Col>
			<Form.Label column md={1}>
				<strong>Days / wk</strong>
			</Form.Label>
			<Col md={2}>
				<SelectField name={`${prefix}.opDaysWk`} isClearable options={daysWeekOptions} />
			</Col>
			<Form.Label column md={1}>
				<strong>Hrs / Day</strong>
			</Form.Label>
			<Col md={2}>
				<TextField name={`${prefix}.hrsDay`} />
			</Col>
		</Row>
		<Row className="mt-2">
			<Form.Label column md={1}>
				<strong>Opportunities</strong>
			</Form.Label>
			<Col md={8}>
				<TextAreaField name={`${prefix}.opportunities`} />
			</Col>
			<Form.Label column md={1}>
				<strong>New Wh</strong>
			</Form.Label>
			<Col md={2}>
				<TextField name={`${prefix}.newWh`} />
			</Col>
		</Row>
		<Row>
			<Col>
				<hr />
			</Col>
		</Row>
	</>);
	// const answer = values.type === EDIT_TYPE_LIST_VALUE
	// 	? <SelectField name={`${prefix}.answer`} options={getListOptions(values.list)} isClearable />
	// 	: <TextField name={`${prefix}.answer`} />;
	//
	// if (isStructureEdit) {
	// 	return <Row className="mt-1">
	// 		<Col md={4} className="d-flex"><div style={{minWidth: 5 + (10 * String(totalLength).length)}}>{index + 1}.</div><div className="w-100"><TextField name={`${prefix}.question`} /></div></Col>
	// 		<Col md={2}><SelectField name={`${prefix}.type`} options={EDIT_TYPE_OPTIONS} /></Col>
	// 		<Col md={3}>{values.type === EDIT_TYPE_LIST_VALUE && <TextAreaField name={`${prefix}.list`} placeholder="List Entries" />}</Col>
	// 		<Col md={2}>{answer}</Col>
	// 		<Col md={1}><TableEditRowActions remove={remove} index={index} totalLength={totalLength} move={move} /></Col>
	// 	</Row>;
	// }
	//
	// return <Row className="mt-1">
	// 	<Col md={6} className="d-flex"><div style={{minWidth: 5 + (10 * String(totalLength).length)}}>{index + 1}.</div><div className="w-100">{values.question}</div></Col>
	// 	<Col md={6}>{answer}</Col>
	// </Row>;
});

// export default function AuditEditEnergyAssessment() {
// 	return <>
// 		<Table size="sm" hover bordered>
// 			<thead>
// 				<tr>
// 					<th>Building<br />Reference</th>
// 					<th>Area</th>
// 					<th>Type</th>
// 					<th>Device<br />Name</th>
// 					<th>Device</th>
// 					<th>Remarks</th>
// 					<th>Quantity</th>
// 					<th>Avg Wh</th>
// 					<th>Op. Days<br />/ wk</th>
// 					<th>hrs / day</th>
// 					<th>Watts new</th>
// 					<th>Opportunities</th>
// 				</tr>
// 			</thead>
// 			<tbody>
// 				<FieldArray
// 					name="energyAssessment"
// 				>
// 					{({form, name, push, remove, move}) => {
// 						const rows = form.values.energyAssessment || [];
// 						return <>
// 							{rows.map((values, index) => {
// 								return <TableRow
// 									key={index}
// 									index={index}
// 									prefix={`${name}[${index}]`}
// 									values={values}
// 									remove={remove}
// 								/>
// 							})}
//
// 							<tr className="mt-1 mb-3">
// 								<td>
// 									<Button onClick={() => push(defaultEntry)} block variant="outline-primary">Add More</Button>
// 								</td>
// 							</tr>
// 						</>;
// 					}}
// 				</FieldArray>
//
// 			</tbody>
// 		</Table>
// 	</>;
// }
//
// const TableRow = memo(({values, prefix, isStructureEdit, move, remove, index, totalLength}) => {
// 	const [editing, setEditing] = useState(false);
//
// 	if (!editing) {
// 		return <tr onDoubleClick={() => setEditing(true)}>
// 			<td>{values.reference}</td>
// 			<td>{values.area}</td>
// 		</tr>
// 	}
//
// 	return <tr>
// 		<td colSpan={99}>
// 			<Container fluid="md">
// 				<Row>
// 					<Col md={2}>
// 						<TextField name={`${prefix}.reference`} />
// 					</Col>
// 				</Row>
// 			</Container>
// 		</td>
// 	</tr>
// return null;
// 	// const answer = values.type === EDIT_TYPE_LIST_VALUE
// 	// 	? <SelectField name={`${prefix}.answer`} options={getListOptions(values.list)} isClearable />
// 	// 	: <TextField name={`${prefix}.answer`} />;
// 	//
// 	// if (isStructureEdit) {
// 	// 	return <Row className="mt-1">
// 	// 		<Col md={4} className="d-flex"><div style={{minWidth: 5 + (10 * String(totalLength).length)}}>{index + 1}.</div><div className="w-100"><TextField name={`${prefix}.question`} /></div></Col>
// 	// 		<Col md={2}><SelectField name={`${prefix}.type`} options={EDIT_TYPE_OPTIONS} /></Col>
// 	// 		<Col md={3}>{values.type === EDIT_TYPE_LIST_VALUE && <TextAreaField name={`${prefix}.list`} placeholder="List Entries" />}</Col>
// 	// 		<Col md={2}>{answer}</Col>
// 	// 		<Col md={1}><TableEditRowActions remove={remove} index={index} totalLength={totalLength} move={move} /></Col>
// 	// 	</Row>;
// 	// }
// 	//
// 	// return <Row className="mt-1">
// 	// 	<Col md={6} className="d-flex"><div style={{minWidth: 5 + (10 * String(totalLength).length)}}>{index + 1}.</div><div className="w-100">{values.question}</div></Col>
// 	// 	<Col md={6}>{answer}</Col>
// 	// </Row>;
// });
