import {memo, useState} from 'react';
import TableEdit, {TableEditRowActions} from './TableEdit';
import {FieldEditor, SelectField, TextAreaField, TextField} from './EditFields';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import {EDIT_TYPE_LIST_VALUE, EDIT_TYPE_OPTIONS} from './editTypes';
import {FieldArray, getIn, useFormikContext} from 'formik';
import Form from 'react-bootstrap/Form';
import PlusSquareIcon from 'react-bootstrap-icons/dist/icons/plus-square';
import {LayoutRow, LayoutRows} from './Layout';
import Dropdown from 'react-bootstrap/Dropdown';

const defaultEntry = {issue: '', type: 'Text', hint: '', observation: '', comment: ''};

export default function AuditEditObservation({isStructureEdit}) {

	const [selected, setSelected] = useState('0');

	const {values} = useFormikContext();

	return <div className="FullScreenTabs">
		<Nav variant="tabs" activeKey={selected}>
			<FieldArray
				name="observation"
			>
				{({form, push, remove, move}) => {
					const tabs = form.values.observation || [];
					return <>
						{tabs.map((values, index) => {
							return <Nav.Item key={index} className="d-none d-lg-block">
								<Nav.Link
									eventKey={index}
									onSelect={setSelected}
									className={getIn(form.errors, `observation[${index}]`) ? 'text-danger' : 'text-info'}
								>
									{values.name || '<missing>'}
								</Nav.Link>
							</Nav.Item>
						})}
						<Dropdown as={Nav.Item} className="d-lg-none">
							<Dropdown.Toggle as={Nav.Link} className={getIn(form.errors, `observation`) ? 'text-danger' : 'text-info'}>Observations</Dropdown.Toggle>
							<Dropdown.Menu>
								{tabs.map((values, index) => {
									return <Dropdown.Item
										key={index}
										eventKey={index}
										onSelect={setSelected}
										className={getIn(form.errors, `observation[${index}]`) ? 'text-danger' : 'text-info'}
									>
										{values.name || '<missing>'}
									</Dropdown.Item>
								})}
							</Dropdown.Menu>
						</Dropdown>
						{isStructureEdit && <Nav.Item className="d-none d-lg-block">
							<Nav.Link eventKey="add" onSelect={() => {
								push({name: 'new', observations: [defaultEntry]});
								setSelected(''+tabs.length);
							}} title="Add Group" className="text-info">
								<PlusSquareIcon />
							</Nav.Link>
						</Nav.Item>}
					</>;
				}}
			</FieldArray>
		</Nav>
		{!!values.observation?.[selected] && <LayoutRows key={selected}>
			{isStructureEdit && <LayoutRow className="m-2">
				<Form.Group>
					<TextField name={`observation[${selected}].name`} label="Name" />
				</Form.Group>
			</LayoutRow>}
			<LayoutRow flexible>
				<TableEdit
					key={selected}
					name={`observation[${selected}].observations`}
					rowComponent={ObservationRow}
					isStructureEdit={isStructureEdit}
					defaultEntry={defaultEntry}
					headerComponent={ObservationHeader}
				/>
			</LayoutRow>
		</LayoutRows>}
	</div>;
}

const ObservationHeader = memo(({isStructureEdit}) => {
	if (isStructureEdit) {
		return <Row>
			<Col md={4}><span className="font-weight-bold">Issue</span></Col>
			<Col md={2}><span className="font-weight-bold">Hint</span></Col>
			<Col md={2}><span className="font-weight-bold">Type</span></Col>
			<Col md={3}><span className="font-weight-bold" /></Col>
			<Col md={1}><span className="font-weight-bold">Actions</span></Col>
		</Row>;
	}

	return <Row>
		<Col md={4}><span className="font-weight-bold">Issue</span></Col>
		<Col md={4}><span className="font-weight-bold">Observation</span></Col>
		<Col md={4}><span className="font-weight-bold">Comment</span></Col>
	</Row>;
});

const ObservationRow = memo(({values, prefix, isStructureEdit, move, remove, index, totalLength}) => {

	if (isStructureEdit) {
		return <Row className="mt-1">
			<Col md={3} className="d-flex"><div style={{minWidth: 5 + (10 * String(totalLength).length)}}>{index + 1}.</div><div className="w-100"><TextAreaField name={`${prefix}.issue`} /></div></Col>
			<Col md={3}><TextAreaField name={`${prefix}.hint`} /></Col>
			<Col md={2}><SelectField name={`${prefix}.type`} options={EDIT_TYPE_OPTIONS} /></Col>
			<Col md={3}>{values.type === EDIT_TYPE_LIST_VALUE && <TextAreaField name={`${prefix}.list`} placeholder="List Entries" />}</Col>
			<Col md={1}><TableEditRowActions remove={remove} index={index} totalLength={totalLength} move={move} /></Col>
		</Row>;
	}

	return <Row className="mt-1">
		<Col md={4} className="d-flex">
			<div style={{minWidth: 5 + (10 * String(totalLength).length)}}>{index + 1}.</div>
			<div className="w-100 ws-pre-wrap">
				{values.issue}
				{!!values.hint && <Form.Text muted>{values.hint}</Form.Text>}
			</div>
		</Col>
		<Col md={4}><FieldEditor values={values} name={`${prefix}.observation`} /></Col>
		<Col md={4}><TextAreaField name={`${prefix}.comment`} /></Col>
	</Row>;
});
