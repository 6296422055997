import {DiscreteColorLegend, HorizontalGridLines, LineSeries, XAxis, XYPlot, YAxis} from 'react-vis';
import moment from 'moment';

// KEEP IN SYNC:
// ui/src/YearlyKWhGraph.js
// server/src/YearlyKWhGraph.js

const newKWhColor = 'lightgreen';
// don't use external CSS classes, this is server side rendered
export default function YearlyKWhGraph({audit}) {
	if (!audit.electricityBills?.length) {
		return 'Enter Electricity Bills';
	}

	const linesData = getElectricityBillsData(audit.electricityBills);

	linesData.unshift(addUsageLineData(audit, linesData[linesData.length - 1]));

	const lines = getChartLines(linesData);

	const legend = audit.electricityBills.map(({startDate}) => {
		return {
			title: `Year ${moment(startDate).format('YY')} kWh`,
		};
	});
	legend.unshift({title: 'New kWh', color: newKWhColor});

	const date = moment(audit.electricityBills[0]?.startDate).date(1);

	const chartWidth = 550;

	const renderLineChart = (
		<div style={{position: 'relative'}}>
			<XYPlot width={chartWidth} height={300}>
				<HorizontalGridLines />
				{lines}
				<XAxis title="Month" tickFormat={v => date.clone().add(v, 'months').format('MMM')} />
				<YAxis title="kWh" />
			</XYPlot>
			<DiscreteColorLegend items={legend} style={{position: 'absolute', top: 0, left: chartWidth}} />
		</div>
	);

	return (
		renderLineChart
	);
}


function getElectricityBillsData(electricityBills) {

	return electricityBills.map(({months}) => {
		return months.map(({kWh}) => {
			return kWh || 0;
		});
	});
}

function getChartLines(linesData) {

	return linesData.map((lineData, index) => {
		const data = lineData.map((y, x) => ({x, y}));

		return <LineSeries key={index} data={data} stroke={index === 0 ? newKWhColor : undefined} />;
	});
}

function addUsageLineData({energyAssessment, auditDate, electricityBills}, lastBillData) {
	if (!lastBillData?.length || !electricityBills?.[0]?.months?.length) {
		return [];
	}

	const auditMonth = moment(auditDate).month();
	const billStartMonth = moment(electricityBills[0].startDate).month();

	const lastBillMonthIndex = (auditMonth + billStartMonth) % 12;

	const monthMulti = 52 / 12;
	const auditMonthKWh = energyAssessment.reduce((total, eai) => {
		const newKWhWk = (eai.quantity * ((eai.newWh ?? eai.wh) / 1000) * eai.opDaysWk * eai.hrsDay) || 0;

		return total + newKWhWk;
	}, 0) * monthMulti;

	return getNewMonthlyKWh(auditMonthKWh, lastBillData[lastBillMonthIndex], lastBillData);
}

function getNewMonthlyKWh(newMonthValue, oldMonthValue, months) {
	let perc = 1 + ((newMonthValue - oldMonthValue) / oldMonthValue);
	if ((perc === Infinity) || isNaN(perc)) {
		perc = 0;
	}
	return months.map(m => m * perc);
}
