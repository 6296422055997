import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-datetime/css/react-datetime.css';
import App from './App';
import DataProvider from './DataProvider';
// import * as serviceWorker from './serviceWorker';
import moment from 'moment'
import 'moment/locale/en-au';

moment.locale('en-au');

ReactDOM.render(
	//<React.StrictMode>
	<DataProvider>
		<App />
	</DataProvider>
	//</React.StrictMode>,
	, document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
