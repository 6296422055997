import {LayoutRow, LayoutRows} from './Layout';
import {TextAreaField} from './EditFields';
import TableEdit, {TableEditRowActions} from './TableEdit';
import {memo, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {useMutation} from '@apollo/client';
import {GET_AUDIT_REPORT} from './graphql';
import {ButtonProgress} from './ButtonProgress';

const defaultEntry = {title: '', elements: []};

export default function ReportMainEdit({isStructureEdit, auditID}) {
	const [getPDF, {loading}] = useMutation(GET_AUDIT_REPORT);
	const [downloadURL, setDownloadURL] = useState(null);
	return (
		<LayoutRows>
			<LayoutRow>
				<Button onClick={async () => {
					setDownloadURL(null);
					try {
						const result = await getPDF({variables: {id: auditID}});
						if (result.error) {
							alert(result.error);
							return;
						}

						while (!(await checkURLExists(result.data.getAuditReport))) {
							await new Promise(resolve => setTimeout(resolve, 2000));
						}

						setDownloadURL(result.data.getAuditReport);
					} catch (e) {
						alert(e.message);
					}
				}} disabled={loading}><ButtonProgress working={loading} /> Generate Report</Button>
				{!!downloadURL && <Button className="ml-2" href={downloadURL} target="_blank">Download PDF</Button>}
			</LayoutRow>
			<LayoutRow flexible>
				<TableEdit
					name={`report.sections`}
					rowComponent={SectionRow}
					isStructureEdit={isStructureEdit}
					defaultEntry={defaultEntry}
					headerComponent={SectionHeader}
				/>
			</LayoutRow>
		</LayoutRows>
	);
}

const SectionHeader = memo(({isStructureEdit}) => {
	if (isStructureEdit) {
		return <Row>
			<Col md={6}><span className="font-weight-bold">Section</span></Col>
			<Col md={2}><span className="font-weight-bold">Actions</span></Col>
		</Row>;
	}

	return <Row>
		<Col md={6}><span className="font-weight-bold">Section</span></Col>
	</Row>;
});

const SectionRow = memo(({prefix, isStructureEdit, move, remove, index, totalLength}) => {

	if (isStructureEdit) {
		return <Row className="mt-1">
			<Col md={6} className="d-flex">
				<div style={{minWidth: 5 + (10 * String(totalLength).length)}}>{index + 1}.</div>
				<div className="w-100"><TextAreaField name={`${prefix}.title`} /></div>
			</Col>
			<Col md={2}><TableEditRowActions remove={remove} index={index} totalLength={totalLength} move={move} /></Col>
		</Row>;
	}

	return <Row className="mt-1">
		<Col md={6} className="d-flex">
			<div style={{minWidth: 5 + (10 * String(totalLength).length)}}>{index + 1}.</div>
			<div className="w-100"><TextAreaField name={`${prefix}.title`} /></div>
		</Col>
	</Row>;
});

export async function checkURLExists(URL) {
	try {
		const response = await fetch(URL, {
			method: 'HEAD',
		});

		if (!response.ok && (response.status !== 304)) {
			return false;
		}

		return true;
	} catch (e) {
		return false;
	}
}
