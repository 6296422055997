import './App.css';
import {Switch, Route, HashRouter, Redirect} from 'react-router-dom';
import AppMenu from './AppMenu';
import {withAuth} from './AuthWrapper';
import {AuditListContainer} from './AuditList';
import {AuditEditContainer} from './AuditEdit';

const App = () => {
	return (
		<HashRouter>
			<div className="d-flex flex-column h-100 overflow-hidden">
				<AppMenu />

				<div className="flex-grow-1 h-100 overflow-hidden">
					<Switch>
						<Route path="/" exact>
							<div>Welcome</div>
							<Redirect to="/audits" />
						</Route>
						<Route path="/audits/:id/:section?/:subSection?" component={AuditEditContainer} />
						<Route path="/audits" component={AuditListContainer} />
						<Route>
							<div>Not found</div>
						</Route>
					</Switch>
				</div>
			</div>
		</HashRouter>
	);
};

export default withAuth(App);
