export function downloadFile(name, data) {
	const a = document.createElement("a");
	a.style.display = 'none';
	document.body.appendChild(a);

	if (!(data instanceof Blob)) {
		if (String(name).match(/\.pdf$/i)) {
			data = new Blob([data], {type: 'application/pdf'});
		} else {
			data = new Blob([data]);
		}
	}

	const objectURL = window.URL.createObjectURL(data);
	a.href = objectURL;
	a.download = name;

	a.click();

	window.URL.revokeObjectURL(objectURL);
	a.href = null;
	document.body.removeChild(a);
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, {type: contentType});
	return blob;
};

export async function uploadFileToS3(URL, file) {
	const response = await fetch(URL, {
		method: 'PUT',
		mode: 'cors',
		body: file,
		headers: {
			'Content-Type': file.type,
			'Cache-Control': 'private, max-age=2419200, immutable',
		},
	});

	if (!response.ok) {
		throw new Error(response.statusText);
	}
}

export function cleanInfinity(v) {
	return Number.isFinite(v) ? v : null;
}
