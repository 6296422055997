import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import ReactSelect from './ReactSelect';
import Row from 'react-bootstrap/Row';
import {useMemo, useState} from 'react';
import {useFormikContext} from 'formik';
import {useApolloClient, useQuery} from '@apollo/client';
import {GET_AUDIT, GET_AUDITS} from './graphql';
import LoadingWrapper from './LoadingWrapper';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import {ButtonProgress} from './ButtonProgress';
import {initBaseline, initDevices, initObservation, initPossibleKPI} from './AuditEdit';

const sourceTypes = [
	{value: 'report', label: 'Report', copy: audit => audit.report},
	{value: 'baseline', label: 'Baseline', copy: audit => initBaseline(audit.baseline)?.map(({answer, ...bld}) => bld) || []},
	{value: 'observation', label: 'Observations', copy: audit => initObservation(audit.observation)?.map(grp => ({
			...grp,
			observations: grp.observations.map(({observation, comment, ...obs}) => obs),
		})) || []},
	{value: 'possibleKPI', label: `KPI's`, copy: audit => initPossibleKPI(audit.possibleKPI)?.map(({relevant, measure, ...kpi}) => kpi) || []},
	{value: 'devices', label: 'Devices', copy: audit => initDevices(audit.devices)},
];

export default function AuditEditCopyData() {
	const [sourceType, setSourceType] = useState(null)
	const [sourceAudit, setSourceAudit] = useState(null)
	const {loading, error, data} = useQuery(GET_AUDITS);
	const client = useApolloClient();
	const [copying, setCopying] = useState(false);
	const [copied, setCopied] = useState(false);

	const audits = useMemo(() => {
		return _.sortBy(
				(data?.audits || [])
					.filter(audit => audit.active)
					.map(audit => ({value: audit.id, label: audit.name})),
				'label')
	}, [data])

	const {dirty, setFieldValue} = useFormikContext();

	if (copied) {
		return (
			<Row>
				<Col>
					<Alert variant="success">
						Review and save the changes
					</Alert>
				</Col>
			</Row>
		);
	}

	if (dirty) {
		return (
			<Row>
				<Col>
					<Alert variant="warning">
						Save this audit first
					</Alert>
				</Col>
			</Row>
		);
	}

	const copy = async () => {
		setCopying(true);
		try {
			const {data, error} = await client.query({
				query: GET_AUDIT,
				variables: {id: sourceAudit},
				fetchPolicy: 'network-only',
			});

			if (error) {
				alert(error);
			} else {
				const copyFunc = sourceTypes.find(st => st.value === sourceType).copy;
				setFieldValue(sourceType, copyFunc(data.audit), true);

				setCopied(true);
			}
		} finally {
			setCopying(false);
		}
	};

	return (
		<LoadingWrapper loading={loading} error={error}>
			<Row>
				<Col>
					<Alert variant="danger">
						Coping data from a different audit overwrites it completely in this audit!
					</Alert>
				</Col>
			</Row>
			<Row>
				<Col>
					<ReactSelect
						onChange={setSourceType}
						options={sourceTypes}
						placeholder="Select data to copy..."
						value={sourceType}
						isClearable
					/>
				</Col>
			</Row>
			<Row className="mt-2">
				<Col>
					<ReactSelect
						onChange={setSourceAudit}
						options={audits}
						placeholder="Select source audit..."
						value={sourceAudit}
						isClearable
					/>
				</Col>
			</Row>
			<Row className="mt-2">
				<Col>
					<Button
						variant="danger"
						onClick={copy}
						disabled={!sourceType || !sourceAudit || copying}
					>
						<ButtonProgress working={copying} />
						Copy!
					</Button>
				</Col>
			</Row>
		</LoadingWrapper>
	);
}
