import {CheckboxField, DateField, TextField} from './EditFields';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {b64toBlob, downloadFile} from './utils';
import {ButtonProgress} from './ButtonProgress';
import {useMutation} from '@apollo/client';
import {GET_AUDIT_EXCEL} from './graphql';

export default function AuditEditMain({auditID}) {
	const [getExcel, {loading}] = useMutation(GET_AUDIT_EXCEL);

	return <>
		<Row className="m-2">
			<Col md={1}>Name</Col>
			<Col md={5}>
				<TextField name="name" />
			</Col>
			<Col md={1}>Project</Col>
			<Col md={5}>
				<TextField name="project" />
			</Col>
		</Row>
		<Row className="m-2">
			<Col md={1}>Client</Col>
			<Col md={5}>
				<TextField name="client" />
			</Col>
			<Col md={1}>Contact</Col>
			<Col md={5}>
				<TextField name="contact" />
			</Col>
		</Row>
		<Row className="m-2">
			<Col md={1}>Audit Date</Col>
			<Col md={5}>
				<DateField name="auditDate" />
			</Col>
		</Row>
		<Row className="m-2">
			<Col md={1}>Tariff</Col>
			<Col md={5}>
				<TextField name="tariffName" />
			</Col>
			<Col md={1}>Tariff Rate ($)</Col>
			<Col md={5}>
				<TextField name="tariffRate" />
			</Col>
		</Row>
		<Row className="m-2">
			<Col md={1}>Author</Col>
			<Col md={5}>
				<TextField name="author" />
			</Col>
			<Col md={1}>Checker</Col>
			<Col md={5}>
				<TextField name="checker" />
			</Col>
		</Row>
		<Row className="m-2">
			<Col md={1}>Emissions Factor</Col>
			<Col md={5}>
				<TextField name="emissionsFactor" />
			</Col>
		</Row>
		<Row className="m-2">
			<Col md={1} />
			<Col md={5}><CheckboxField name="active" label="Active" /></Col>
		</Row>
		<Row className="m-2 mt-5">
			<Col>
				<Button
					onClick={async () => {
						try {
							const result = await getExcel({variables: {id: auditID}});
							if (result.error) {
								alert(result.error);
								return;
							}
							downloadFile('Audit.xlsx', b64toBlob(result.data.getAuditExcel, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'));
						} catch (e) {
							alert(e.message);
						}
						}}
					disabled={loading}>
					<ButtonProgress working={loading} />
					Download Excel
				</Button>
			</Col>
		</Row>
	</>;
}
