import {gql} from '@apollo/client';

export const GET_AUDITS = gql`
    query GetAudits {
        audits {
            id
            name
			project
			active
            updated
			auditDate
        }
    }
`;

const auditFields = gql`
    fragment auditFields on Audit {
        id
        updated
        name
        auditDate
        project
        tariffName
        tariffRate
		active
        client
        contact
        author
        checker
        emissionsFactor
        baseline {
            question
            answer
            type
            list
        }
        observation {
            name
            observations {
                issue
                hint
                observation
                type
                list
				comment
            }
        }
        areas {
            short
            name
        }
        deviceTypes {
            short
            name
        }
        devices {
            type
            name
            wh
            opportunities
        }
        energyAssessment {
            reference
            area
            deviceType
            device
            remarks
            quantity
            wh
            opDaysWk
            hrsDay
            opportunities
            newWh
        }
        possibleKPI {
            kpi
            relevant
            measure
            type
            list
        }
        electricityBills {
            startDate
            months {
				kWh
				cost
			}
        }
        report {
            sections {
                title
                elements {
                    type
                    ... on REContent {
                        content
                    }
                    ... on RETitle {
                        title
                    }
                    ... on REInternalText {
                        text
                    }
                    ... on REApplianceLoadTable {
                        deviceType
                    }
                }
            }
        }
    }
`;

export const GET_AUDIT = gql`
    query GetAudit($id: ID!) {
        audit(id: $id) {
            ...auditFields
        }
    }

    ${auditFields}
`;

export const GET_AUDIT_REPORT = gql`
    mutation GetAuditReport($id: ID!) {
        getAuditReport(id: $id)
    }
`;

export const GET_AUDIT_EXCEL = gql`
    mutation GetAuditExcel($id: ID!) {
        getAuditExcel(id: $id)
    }
`;

export const SAVE_AUDIT = gql`
    mutation SaveAudit(
        $audit: AuditInput!
    ) {
        saveAudit(
            audit: $audit
        ) {
            ...auditFields
        }
    }

    ${auditFields}
`;

export const CLONE_AUDIT = gql`
    mutation CloneAudit(
        $id: ID
    ) {
        cloneAudit(
            id: $id
        ) {
            ...auditFields
        }
    }

    ${auditFields}
`;

export const GENERATE_FILE_UPLOAD_URL = gql`
    mutation GenerateFileUploadURL($auditID: ID!, $name: String!, $contentType: String!, $type: String) {
        generateFileUploadURL(auditID: $auditID, name: $name, contentType: $contentType, type: $type) {
            id
            URL
        }
    }
`;

export const GENERATE_FILE_DOWNLOAD_URL = gql`
    mutation GenerateFileDownloadURL($auditID: ID!, $id: ID!, $name: String!, $type: String) {
        generateFileDownloadURL(auditID: $auditID, id: $id, name: $name, type: $type)
    }
`;
