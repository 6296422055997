import {useFormikContext} from 'formik';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {useHistory, useParams} from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import EnergySummary from './EnergySummary';
import {LayoutRow, LayoutRows} from './Layout';

export default function AuditEditUsageCostsSavings() {
	const history = useHistory();

	const {id, subSection: deviceType} = useParams();


	const {values} = useFormikContext();
	const {deviceTypes, energyAssessment, tariffRate} = values;

	return <div className="FullScreenTabs">
		<Tabs
			transition={false}
			mountOnEnter
			unmountOnExit
			activeKey={deviceType}
			defaultActiveKey="summary"
			onSelect={deviceType => history.replace(`/audits/${id}/usageCostsSavings/${deviceType}`)}
		>
			<Tab eventKey="summary" title="Summary" tabClassName="text-info">
				<LayoutRows>
					<LayoutRow scrolling>
						<EnergySummary audit={values} />
					</LayoutRow>
				</LayoutRows>
			</Tab>
			{deviceTypes.map(({short, name}) => {
				return (
					<Tab key={short} eventKey={short} title={name} tabClassName="text-info">
						<LayoutRows>
							<LayoutRow scrolling>
								<DeviceTypeList deviceType={short} energyAssessment={energyAssessment} tariffRate={tariffRate} />
							</LayoutRow>
						</LayoutRows>
					</Tab>
				);
			})}
		</Tabs>
	</div>;
}

const DeviceTypeList = ({deviceType, energyAssessment, tariffRate}) => {

	let totalOldKWhWk = 0;
	let totalNewKWhWk = 0;
	let totalKWhSavings = 0;
	let totalCost = 0;

	const rows = energyAssessment.filter(eai => eai.deviceType === deviceType).map((eai, index) => {
		const oldKWhWk = (eai.quantity * (eai.wh / 1000) * eai.opDaysWk * eai.hrsDay) || null;
		const newKWhWk = (eai.quantity * ((eai.newWh ?? eai.wh) / 1000) * eai.opDaysWk * eai.hrsDay) || null;
		const kWhSavings = (oldKWhWk && newKWhWk) ? (oldKWhWk - newKWhWk) : null;
		const cost = (kWhSavings && tariffRate) ? (kWhSavings * tariffRate) : null

		totalOldKWhWk += oldKWhWk || 0;
		totalNewKWhWk += newKWhWk || 0;
		totalKWhSavings += kWhSavings || 0;
		totalCost += cost || 0;

		return <TableRow
			key={index}
			eai={eai}
			tariffRate={tariffRate}
			oldKWhWk={oldKWhWk}
			newKWhWk={newKWhWk}
			kWhSavings={kWhSavings}
			cost={cost}
		/>
	});

	const monthMulti = 52 / 12;

	return (<>
		<Table size="sm" hover bordered className="mt-2">
			<thead>
				<tr>
					<th>Reference</th>
					<th>Area</th>
					<th>Device</th>
					<th>Remarks</th>
					<th className="text-right">KWh / wk</th>
					<th>Opportunities</th>
					<th className="text-right">KWh / wk new</th>
					<th className="text-right">Savings Pot.</th>
					<th className="text-right">COST</th>
				</tr>
			</thead>
			<tbody>
				{rows}
				<tr className="font-weight-bold">
					<td colSpan={4} className="text-right">
						Weekly:
					</td>
					<td className="text-right">
						{formatKWh(totalOldKWhWk)}
					</td>
					<td />
					<td className="text-right">
						{formatKWh(totalNewKWhWk)}
					</td>
					<td className="text-right">
						{formatKWh(totalKWhSavings)}
					</td>
					<td className="text-right">
						{formatCurrency(totalCost)}
					</td>
				</tr>
				<tr className="font-weight-bold">
					<td colSpan={4} className="text-right">
						Monthly:
					</td>
					<td className="text-right">
						{formatKWh(totalOldKWhWk * monthMulti)}
					</td>
					<td />
					<td className="text-right">
						{formatKWh(totalNewKWhWk * monthMulti)}
					</td>
					<td className="text-right">
						{formatKWh(totalKWhSavings * monthMulti)}
					</td>
					<td className="text-right">
						{formatCurrency(totalCost * monthMulti)}
					</td>
				</tr>
				<tr className="font-weight-bold">
					<td colSpan={4} className="text-right">
						Yearly:
					</td>
					<td className="text-right">
						{formatKWh(totalOldKWhWk * 52)}
					</td>
					<td />
					<td className="text-right">
						{formatKWh(totalNewKWhWk * 52)}
					</td>
					<td className="text-right">
						{formatKWh(totalKWhSavings * 52)}
					</td>
					<td className="text-right">
						{formatCurrency(totalCost * 52)}
					</td>
				</tr>
			</tbody>
		</Table>

	</>);
};

const kWhFmt = new Intl.NumberFormat('en-AU', {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

const currencyFmt = new Intl.NumberFormat('en-AU', {
	style: 'currency',
	currency: 'AUD',
});

function formatKWh(value) {
	if (!value) {
		return null;
	}

	return kWhFmt.format(value);
}

function formatCurrency(value) {
	if (!value) {
		return null;
	}

	return currencyFmt.format(value);
}

const TableRow = ({eai, oldKWhWk, newKWhWk, kWhSavings, cost}) => {

	return (
		<tr>
			<td>
				{eai.reference}
			</td>
			<td>
				{eai.area}
			</td>
			<td className="text-wrap text-break">
				{eai.device}
			</td>
			<td className="text-wrap text-break">
				{eai.remarks}
			</td>
			<td className="text-right">
				{formatKWh(oldKWhWk)}
			</td>
			<td className="text-wrap text-break">
				{eai.opportunities}
			</td>
			<td className="text-right">
				{formatKWh(newKWhWk)}
			</td>
			<td className="text-right">
				{formatKWh(kWhSavings)}
			</td>
			<td className="text-right">
				{formatCurrency(cost)}
			</td>
		</tr>
	);
}
