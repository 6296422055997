import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, from, ApolloLink, fromError} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {Auth} from '@aws-amplify/auth';
import {GraphQLError} from 'graphql';

const httpLink = createHttpLink({
	uri: (window.location.hostname === 'localhost' ? 'http://localhost:3782/graphql' : `https://${window.location.hostname}/graphql`),
});

const authLink = setContext(async (_, {headers}) => {
	const token = await getToken();

	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		}
	};
});

async function getToken() {
	try {
		const user = await Auth.currentAuthenticatedUser();

		return user
			?.getSignInUserSession()
			?.getAccessToken()
			?.getJwtToken();
	} catch(e) {
		console.log(e);
		return null;
	}
}

const errorLink = onError(({graphQLErrors, networkError, response, operation}) => {
	if (graphQLErrors?.find(e => e.extensions?.code === 'UNAUTHENTICATED')) {
		return fromError(new GraphQLError('Not logged in'))
	}
});

// TODO reset cache after login/out: https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout

const omitTypenameLink = new ApolloLink((operation, forward) => {
	if (operation.variables) {
		operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
	}

	return forward(operation)
});

function omitTypename(key, value) {
	return key === '__typename' ? undefined : value
}

const client = new ApolloClient({
	link: from([
		omitTypenameLink,
		authLink,
		errorLink,
		httpLink
	]),
	cache: new InMemoryCache(),
	defaultOptions: {
		query: {
			fetchPolicy: 'cache-and-network',
		},
	},
});

export default function DataProvider({children}) {
	return (
		<ApolloProvider client={client}>
			{children}
		</ApolloProvider>
	);
}
