import {useCallback, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import Spinner from 'react-bootstrap/Spinner';
import {useMutation} from '@apollo/client';
import {GENERATE_FILE_DOWNLOAD_URL, GENERATE_FILE_UPLOAD_URL} from './graphql';
import useSubmitBlock from './useSubmitBlock';
import Button from 'react-bootstrap/Button';
import TrashIcon from 'react-bootstrap-icons/dist/icons/trash';
import DownloadIcon from 'react-bootstrap-icons/dist/icons/download';
import {uploadFileToS3} from './utils';

export function FieldFileManager({value, onChange, name, auditID}) {
	const files = JSON.parse(value || '[]');

	const [uploading, setUploading] = useState(null);

	const [generateFileUploadURL] = useMutation(GENERATE_FILE_UPLOAD_URL);
	const [generateFileDownloadURL] = useMutation(GENERATE_FILE_DOWNLOAD_URL);

	const {block, unblock} = useSubmitBlock();

	const setValue = useCallback(value => {
		onChange({
			target: {
				value: JSON.stringify(value),
				name,
			},
		});
	}, [onChange, name]);

	const onDrop = useCallback(async acceptedFiles => {
		const file = acceptedFiles[0];

		setUploading(file.name);
		block();
		try {
			const {data: {generateFileUploadURL: {id, URL}}} = await generateFileUploadURL({variables: {auditID, name: file.name, contentType: file.type}});

			await uploadFileToS3(URL, file);

			setValue(files.concat({
				name: file.name,
				id,
			}));
		} catch (e) {
			// TODO better
			alert(e.message);
		}
		unblock();
		setUploading(false);
	}, [files, setValue, generateFileUploadURL, auditID, block, unblock]);

	if (uploading) {
		return <div><Spinner animation="border" className="mr-3" />Uploading {uploading}...</div>;
	}

	return (<>
		{files.map((file, i) =>
			<div key={i} className="d-flex mb-2">
				<Button variant="outline-secondary" onClick={async () => {
					const {data: {generateFileDownloadURL: URL}} = await generateFileDownloadURL({variables: {auditID, name: file.name, id: file.id}});

					window.open(URL, '_blank');
				}} title="Download File"><DownloadIcon /></Button>
				<div className="flex-grow-1 ml-2 mr-2">
					{file.name}
				</div>
				<Button variant="outline-danger" onClick={() => {
					setValue(files.filter(({id}) => id !== file.id));
				}} title="Delete File"><TrashIcon /></Button>
			</div>
		)}

		<StyledDropzone onDrop={onDrop} />
	</>);
}

function StyledDropzone({onDrop, accept}) {

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone({onDrop, multiple: false, accept});

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isDragActive,
		isDragReject,
		isDragAccept
	]);

	return (
		<div {...getRootProps({style})}>
			<input {...getInputProps()} />
			{
				isDragActive ?
					<p>Drop the file here ...</p> :
					<p>Drag 'n' drop a file here, or click to select a file</p>
			}
		</div>
	);
}

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: 5,
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
	cursor: 'pointer',
};

const activeStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};
