import HTMLEditor from '../HTMLEditor';
import {useFormikContext} from 'formik';
import {memo, useCallback, useMemo} from 'react';
import {useMutation} from '@apollo/client';
import {GENERATE_FILE_DOWNLOAD_URL, GENERATE_FILE_UPLOAD_URL} from '../graphql';
import {uploadFileToS3} from '../utils';

const Content = memo(({onChange, initialValue, uploadImageCallback}) => {
	return <div>
		<HTMLEditor initialValue={initialValue} onChange={onChange} uploadImageCallback={uploadImageCallback} />
	</div>;
});

export default function ContentWrapper({prefix, values}) {

	const {setFieldValue, values: formValues} = useFormikContext();
	const auditID = formValues.id;

	const [generateFileUploadURL] = useMutation(GENERATE_FILE_UPLOAD_URL);
	const [generateFileDownloadURL] = useMutation(GENERATE_FILE_DOWNLOAD_URL);

	const uploadImageCallback = useCallback(async file => {
		try {
			const {data: {generateFileUploadURL: {id, URL: uploadURL}}} = await generateFileUploadURL({variables: {auditID, name: file.name, contentType: file.type, type: 'reportImage'}});
			await uploadFileToS3(uploadURL, file);

			const {data: {generateFileDownloadURL: downloadURL}} = await generateFileDownloadURL({variables: {auditID, name: file.name, id: id, type: 'reportImage'}});

			return {data: {link: downloadURL}};
		} catch (e) {
			// TODO better
			alert(e.message);
			throw e;
		}
	}, [generateFileUploadURL, generateFileDownloadURL, auditID]);

	const onChange = useCallback(value => {
		setFieldValue(`${prefix}.content`, value);
	}, [prefix, setFieldValue]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const initialValue = useMemo(() => values.content, []);

	return <Content onChange={onChange} initialValue={initialValue} uploadImageCallback={uploadImageCallback} />;
};
