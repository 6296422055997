import Spinner from 'react-bootstrap/Spinner';

export function ButtonProgress({working}) {
	if (working) {
		return <Spinner animation="border" size="sm" className="mr-2" />;
	} else {
		return <div className="spinner-placeholder mr-2" style={{
			width: '1rem',
			height: '1rem',
			borderWidth: '.2em',
			display: 'inline-block',
		}} />;
	}
}
