import {useCallback, useMemo, useState} from 'react';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import {useHistory} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {CLONE_AUDIT, GET_AUDITS} from './graphql';
import LoadingWrapper from './LoadingWrapper';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import moment from 'moment';

const rowStyle = {cursor: 'pointer'};

const filter = filterFactory();

function ActionButtons({clone, id}) {
	const [cloning, setCloning] = useState(false);

	return <Button onClick={e => {
		e.stopPropagation();
		setCloning(true);
		clone(id);
	}} disabled={cloning} variant="outline-secondary">Clone</Button>;
}

const defaultSorted = [{dataField: 'auditDate', order: 'desc'}];

function AuditList({audits, clone}) {
	const history = useHistory();

	const [showInactive, setShowInactive] = useState(false);

	const rowEvents = useMemo(() => {
		return {
			onClick: (e, row, rowIndex) => {
				history.push(`/audits/${row.id}`);
			},
		};
	}, [history]);

	const getRowClasses = useCallback(row => {
		if (!row.active) {
			return 'text-black-50';
		}
	}, []);

	const columns = [
		{
			dataField: 'auditDate',
			text: 'Date',
			sort: true,
			formatter: (cell, row, x) => {
				return cell ? moment(cell).format('DD/MM/YYYY') : null;
			},
		},
		{
			dataField: 'name',
			text: 'Name',
			filter: textFilter({
				placeholder: 'Filter...',
			}),
			sort: true,
		},
		{
			dataField: 'project',
			text: 'Project',
			filter: textFilter({
				placeholder: 'Filter...',
			}),
			sort: true,
		},
		{
			dataField: 'actions',
			text: 'Actions',
			formatter: (cell, row, x, {clone}) => {
				return <ActionButtons id={row.id} clone={clone} />
			},
			formatExtraData: {
				clone,
			},
		},
		{
			dataField: 'updated',
			text: 'Updated',
			sort: true,
			formatter: (cell, row, x) => {
				const date = moment(cell);
				return <span title={date.format('LLL')}>{date.fromNow()}</span>;
			},
		},
	];

	const filteredAudits = useMemo(() => {
		if (showInactive) {
			return audits;
		} else {
			return audits.filter(audit => audit.active);
		}
	}, [audits, showInactive]);

	return <div className="d-flex flex-grow-1 flex-column h-100 overflow-auto">
		<BootstrapTable
			bootstrap4
			keyField="id"
			data={filteredAudits}
			columns={columns}
			filter={filter}
			striped
			hover
			condensed
			rowEvents={rowEvents}
			rowStyle={rowStyle}
			rowClasses={getRowClasses}
			defaultSorted={defaultSorted}
		/>

		<hr />

		<Form.Check
			id="AuditListToggleDeactivated"
			type="checkbox"
			label="Show all Audits"
			value={true}
			checked={showInactive}
			onChange={() => setShowInactive(!showInactive)}
		/>
	</div>;
}

export function AuditListContainer() {
	const {loading, error, data, refetch} = useQuery(GET_AUDITS);

	const history = useHistory();

	const [serverCloneAudit] = useMutation(CLONE_AUDIT);

	async function clone(id) {
		const {data: {cloneAudit}} = await serverCloneAudit({variables: {id}});

		await refetch();

		history.push(`/audits/${cloneAudit.id}`);
	}

	return <LoadingWrapper loading={loading} error={error}>
		<AuditList audits={data?.audits} clone={clone} />
	</LoadingWrapper>;
}
