import {LayoutRow, LayoutRows} from './Layout';
import TableEdit, {TableEditRowActions} from './TableEdit';
import {memo} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToC from './reportElements/ToC';
import ReactSelect from './ReactSelect';
import PageBreak from './reportElements/PageBreak';
import CoverPage from './reportElements/CoverPage';
import LastPage from './reportElements/LastPage';
import Content from './reportElements/Content';
import Title from './reportElements/Title';
import InternalText from './reportElements/InternalText';
import YearlyKWhGraph from './reportElements/YearlyKWhGraph';
import ApplianceLoadTable from './reportElements/ApplicanceLoadTable';
import AuditInfo from './reportElements/AuditInfo';
import ElectricalDevicesTable from './reportElements/ElectricalDevicesTable';
import EnergyAuditData from './reportElements/EnergyAuditData';
import PotentialSustainabilityOutcomesTable from './reportElements/PotentialSustainabilityOutcomesTable';
import ObservationData from './reportElements/ObservationData';
import LoadsComparisonGraph from './reportElements/LoadsComparisonGraph';

const reportElements = {
	ToC,
	PageBreak,
	CoverPage,
	LastPage,
	Content,
	Title,
	YearlyKWhGraph,
	ApplianceLoadTable,
	AuditInfo,
	InternalText,
	ElectricalDevicesTable,
	PotentialSustainabilityOutcomesTable,
	EnergyAuditData,
	ObservationData,
	LoadsComparisonGraph,
};

const reportElementOptions = [
	{value: 'Title', label: 'Title'},
	{value: 'Content', label: 'Content'},
	{value: 'PageBreak', label: 'Page Break'},
	{value: 'ToC', label: 'Table of Contents'},
	{value: 'YearlyKWhGraph', label: 'Yearly kWh Graph'},
	{value: 'LoadsComparisonGraph', label: 'Loads Comparison Graph'},
	{value: 'ApplianceLoadTable', label: 'Appliance Load Table'},
	{value: 'AuditInfo', label: 'Audit Info'},
	{value: 'InternalText', label: 'Internal Text (not printed)'},
	{value: 'ElectricalDevicesTable', label: 'Electrical Devices Table'},
	{value: 'PotentialSustainabilityOutcomesTable', label: 'Potential Sustainability Outcomes Table'},
	{value: 'EnergyAuditData', label: 'Energy Audit Data'},
	{value: 'ObservationData', label: 'Observation Data'},
	{value: 'CoverPage', label: 'Cover Page'},
	{value: 'LastPage', label: 'Last Page'},
];

const defaultEntry = {type: ''};

export default function ReportSectionEdit({isStructureEdit, selected}) {
	return (
		<LayoutRows>
			<LayoutRow flexible>
				<TableEdit
					name={`report.sections[${selected}].elements`}
					rowComponent={ElementRow}
					isStructureEdit={isStructureEdit}
					defaultEntry={defaultEntry}
					headerComponent={ElementHeader}
					addComponent={ElementAdd}
				/>
			</LayoutRow>
		</LayoutRows>
	);
}

const ElementAdd = memo(({add, isStructureEdit, defaultEntry}) => {
	return (
		<Col md={6} lg={4}>
			<ReactSelect
				onChange={type => add({...defaultEntry, type})}
				options={reportElementOptions}
				placeholder="Select type to add new element..."
			/>
		</Col>
	);
});

const ElementHeader = memo(({isStructureEdit}) => {
	if (isStructureEdit) {
		return <Row>
			<Col md={10}><span className="font-weight-bold">Element</span></Col>
			<Col md={2}><span className="font-weight-bold">Actions</span></Col>
		</Row>;
	}

	return <Row>
		<Col md={12}><span className="font-weight-bold">Element</span></Col>
	</Row>;
});

const ElementRow = memo(({values, prefix, isStructureEdit, move, remove, index, totalLength}) => {
	const RE = reportElements[values.type];
	const re = <RE prefix={prefix} values={values} />;

	if (isStructureEdit) {
		return <Row className="mt-1">
			<Col md={10} className="d-flex">
				<div style={{minWidth: 5 + (10 * String(totalLength).length)}}>{index + 1}.</div>
				<div className="w-100">
					{re}
				</div>
			</Col>
			<Col md={2}><TableEditRowActions remove={remove} index={index} totalLength={totalLength} move={move} /></Col>
		</Row>;
	}

	return <Row className="mt-1">
		<Col md={12} className="d-flex">
			<div style={{minWidth: 5 + (10 * String(totalLength).length)}}>{index + 1}.</div>
			<div className="w-100">
				{re}
			</div>
		</Col>
	</Row>;
});
