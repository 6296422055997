import Select from 'react-select';

const customStyles = {
	// control: (provided) => ({
	// 	// ...provided, // styled through css
	// }),
	menu: (provided) => ({
		...provided,
		zIndex: 5,
	}),
};

const ReactSelect = ({
	isInvalid,
	isValid,
	className,
	onChange,
	value,
	...props
}) => {
	const onInternalChange = option => {
		const value = props.isMulti
			? option ? option.map(item => item.value) : null
			: option ? option.value : null;

		onChange(value);
	};

	const getValue = () => {
		if (props.options) {
			return props.isMulti
				? value ? props.options.filter(option => value.includes(option.value)) : []
				: props.options.find(option => option.value === value);
		} else {
			return props.isMulti ? [] : '';
		}
	};

	return (
		<Select
			value={getValue() || null}
			onChange={onInternalChange}
			className={`${isInvalid ? 'is-invalid' : ''} ${isValid ? 'is-valid' : ''} ${className}`}
			styles={customStyles}
			menuPlacement="auto"
			{...props}
		/>
	);
};

export default ReactSelect;
