
export const EDIT_TYPE_TEXT = 'Text';
export const EDIT_TYPE_LIST_VALUE = 'ListValue';
export const EDIT_TYPE_FILES = 'Files';

export const EDIT_TYPE_OPTIONS = [
	{value: EDIT_TYPE_TEXT, label: 'Text'},
	{value: EDIT_TYPE_LIST_VALUE, label: 'List Value'},
	{value: EDIT_TYPE_FILES, label: 'Files'},
];

export const EDIT_TYPES = [
	EDIT_TYPE_TEXT,
	EDIT_TYPE_LIST_VALUE,
	EDIT_TYPE_FILES,
];
