import {useEffect, useState} from 'react';
import {Auth} from '@aws-amplify/auth';
import {onAuthUIStateChange, AuthState} from '@aws-amplify/ui-components';

Auth.configure({
	aws_cognito_region: process.env.REACT_APP_AWS_REGION,
	aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
	aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
	aws_mandatory_sign_in: 'enable',
	oauth: {
		domain: 'eea-login.auth.ap-southeast-2.amazoncognito.com', // TODO set from ENV
		scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
		redirectSignIn: `${window.location.protocol}//${window.location.host}`,
		redirectSignOut: `${window.location.protocol}//${window.location.host}`,
		responseType: 'code',
	},
});

export function withAuth(Component) {
	const AppWithAuth = props => {
		const [signedIn, setSignedIn] = useState(null);
		const [error, setError] = useState(null);

		useEffect(() => {
			return checkUser();
		});

		function checkUser() {
			setUser();

			return onAuthUIStateChange(authState => {
				if (authState === AuthState.SignedIn) {
					setSignedIn(true);
				} else if (authState === AuthState.SignedOut) {
					setSignedIn(false);
				}
			});
		}

		async function setUser() {
			try {
				const user = await Auth.currentAuthenticatedUser();
				setSignedIn(!!user);
			} catch (err) {
				console.debug(err);
				setError(err.message);
				setSignedIn(false);
			}
		}

		useEffect(() => {
			if (signedIn === false) {
				Auth.federatedSignIn();
			}
		}, [signedIn]);


		if (error) {
			return <div>An error occurred: {error}</div>;
		}

		if (!signedIn) {
			return <div>Loading...</div>;
		}

		return <>
			<Component />
		</>;
	};

	return AppWithAuth;
}
