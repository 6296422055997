import {memo, forwardRef} from 'react';
import {Dropdown} from 'react-bootstrap';
import ArrowsMoveIcon from 'react-bootstrap-icons/dist/icons/arrows-move';
import _ from 'lodash';

export const MoveDropdown = memo(({totalLength, move, index}) => {
	return (
		<Dropdown alignRight>
			<Dropdown.Toggle
				size="sm"
				className="icon-button dropdown-no-toggle h-100"
				variant="light"
				title="Move to another position"
			>
				<ArrowsMoveIcon />
			</Dropdown.Toggle>
			<Dropdown.Menu
				as={DropdownPanel}
				totalLength={totalLength}
				move={move}
				index={index}
			/>
		</Dropdown>
	);
});

const DropdownPanel = forwardRef(
	({children, style, className, show, totalLength, move, index}, ref) => {
		if (!show) {
			return null;
		}

		return (
			<div
				ref={ref}
				style={style}
				className={className}
			>
				{_.range(0, totalLength).map(i => {
					return <Dropdown.Item key={i} onClick={() => move(index, i)} disabled={index === i}>{i + 1}</Dropdown.Item>
				})}
			</div>
		);
	},
);
