import {useState} from 'react';
import Nav from 'react-bootstrap/Nav';
import {FieldArray, getIn, useFormikContext} from 'formik';
import ReportMainEdit from './ReportMainEdit';
import ReportSectionEdit from './ReportSectionEdit';
import Dropdown from 'react-bootstrap/Dropdown';

export default function AuditEditReport({isStructureEdit, auditID}) {

	const [selected, setSelected] = useState('main');

	const {values} = useFormikContext();

	return <div className="FullScreenTabs">
		<Nav variant="tabs" activeKey={selected}>
			<FieldArray
				name="report.sections"
			>
				{({form, push, remove, move}) => {
					const tabs = form.values.report?.sections || [];
					return <>
						<Nav.Item>
							<Nav.Link
								eventKey="main"
								onSelect={setSelected}
								className={getIn(form.errors, `report`) ? 'text-danger' : 'text-info'}
							>
								Report
							</Nav.Link>
						</Nav.Item>
						{tabs.map((values, index) => {
							return <Nav.Item key={index} className="d-none d-lg-block">
								<Nav.Link
									eventKey={index}
									onSelect={setSelected}
									className={getIn(form.errors, `report.sections[${index}]`) ? 'text-danger' : 'text-info'}
								>
									{values.title || '<missing>'}
								</Nav.Link>
							</Nav.Item>
						})}
						<Dropdown as={Nav.Item} className="d-lg-none">
							<Dropdown.Toggle as={Nav.Link} className={getIn(form.errors, `report.sections`) ? 'text-danger' : 'text-info'}>Sections</Dropdown.Toggle>
							<Dropdown.Menu>
								{tabs.map((values, index) => {
									return <Dropdown.Item
										key={index}
										eventKey={index}
										onSelect={setSelected}
										className={getIn(form.errors, `report.sections[${index}]`) ? 'text-danger' : 'text-info'}
									>
										{values.title || '<missing>'}
									</Dropdown.Item>
								})}
							</Dropdown.Menu>
						</Dropdown>
					</>;
				}}
			</FieldArray>
		</Nav>

		{(selected === 'main') && <ReportMainEdit auditID={auditID} isStructureEdit={isStructureEdit} />}
		{!!values.report?.sections?.[selected] && <ReportSectionEdit key={selected} selected={selected} isStructureEdit={isStructureEdit} />}
	</div>;
}
