import {PureComponent} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './HTMLEditor.css';

export default class HTMLEditor extends PureComponent {

	state = {
		editorState: this.getInitialEditorState(),
	};

	getInitialEditorState() {
		const contentBlock = htmlToDraft(this.props.initialValue || '');
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			return EditorState.createWithContent(contentState);
		}

		return EditorState.createEmpty();
	}

	onEditorStateChange = (editorState) => {
		this.props.onChange && this.props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));

		this.setState({editorState});
	};

	render() {
		const {placeholder, uploadImageCallback} = this.props;

		return (
			<>
				<Editor
					editorState={this.state.editorState}
					onEditorStateChange={this.onEditorStateChange}
					placeholder={placeholder}
					editorClassName="HTMLEditor"
					spellCheck
					toolbar={{image: {
						uploadCallback: uploadImageCallback,
						previewImage: true,
					}}}
				/>
			</>
		);
	}

}
