import {SelectField} from '../EditFields';
import {getTypeOptions} from '../dataUtils';
import {useFormikContext} from 'formik';

export default function ApplianceLoadTable({prefix}) {
	const {values} = useFormikContext();

	const deviceTypeOptions = getTypeOptions(values.deviceTypes);

	return <SelectField
		name={`${prefix}.deviceType`}
		label="Appliance Load Table - Type"
		options={deviceTypeOptions}
		isClearable
	/>;
}
