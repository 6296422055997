import ReactSelect from './ReactSelect';

const FormikSelect = ({
	name,
	onChange,
	onBlur,
	onSelect,
	...props
}) => {
	const onInternalChange = value => {
		onChange({target: {value, name}});
		onSelect && onSelect({target: {value, name}});
	};

	const onInternalBlur = () => {
		onBlur({target: {name}});
	};

	return (
		<ReactSelect
			onChange={onInternalChange}
			onBlur={onInternalBlur}
			{...props}
		/>
	);
};

export default FormikSelect;
