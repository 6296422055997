import {FieldArray, getIn} from 'formik';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import TrashIcon from 'react-bootstrap-icons/dist/icons/trash';
import {MoveDropdown} from './MoveDropdown';
import {LayoutRow, LayoutRows} from './Layout';

export default function TableEdit({
	name,
	isStructureEdit,
	headerComponent: Header,
	rowComponent: TableRow,
	defaultEntry,
	addComponent: Add,
}) {
	Add = Add || AddMore;
	return <LayoutRows className="mt-1">
		<LayoutRow className="pl-3 pr-4">
			<Header isStructureEdit={isStructureEdit} />
		</LayoutRow>
		<LayoutRow flexible scrolling className="pl-3 pr-3">
			<FieldArray
				name={name}
			>
				{({form, name, push, remove, move}) => {
					const rows = getIn(form.values, name) || [];

					const moveFixed = (from, to) => {
						move(from, to);
						setTimeout(() => form.validateForm(), 0); // fixes undefined error validation messages
					};

					return <>
						{rows.map((values, index) => {
							return <TableRow
								key={index}
								index={index}
								totalLength={rows.length}
								prefix={`${name}[${index}]`}
								values={values}
								isStructureEdit={isStructureEdit}
								remove={remove}
								move={moveFixed}
							/>
						})}

						{isStructureEdit && <hr />}
						<Row className="mt-1 mb-3">
							{isStructureEdit && <Add add={push} defaultEntry={defaultEntry} />}
						</Row>
					</>;
				}}
			</FieldArray>
		</LayoutRow>
	</LayoutRows>;
}

function AddMore({add, defaultEntry}) {
	return <Col md={2}><Button onClick={() => add(defaultEntry)} block variant="outline-primary">Add More</Button></Col>;
}

export function TableEditRowActions({remove, move, index, totalLength}) {
	return <ButtonToolbar>
		<ButtonGroup>
			<Button
				title="Remove"
				variant="outline-danger"
				size="sm"
				onClick={() => remove(index)}
			>
				<TrashIcon />
			</Button>
		</ButtonGroup>
		{move && <ButtonGroup>
			<MoveDropdown totalLength={totalLength} move={move} index={index} />
		</ButtonGroup>}
	</ButtonToolbar>
}
