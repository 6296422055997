import {useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {GET_AUDIT, SAVE_AUDIT} from './graphql';
import LoadingWrapper from './LoadingWrapper';
import {Formik, getIn} from 'formik';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import AuditEditBaseline from './AuditEditBaseline';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import * as yup from 'yup';
import {EDIT_TYPE_LIST_VALUE, EDIT_TYPES} from './editTypes';
import moize from 'moize';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import AuditEditObservation from './AuditEditObservation';
import AuditEditDevices from './AuditEditDevices';
import AuditEditMain from './AuditEditMain';
import AuditEditEnergyAssessment from './AuditEditEnergyAssessment';
import useSubmitBlock, {SubmitBlock} from './useSubmitBlock';
import AuditEditUsageCostsSavings from './AuditEditUsageCostsSavings';
import AuditEditPossibleKPI from './AuditEditPossibleKPI';
import ElectricityBills from './ElectricityBills';
import AuditEditReport from './AuditEditReport';
import _ from 'lodash';
import {ButtonProgress} from './ButtonProgress';
import AuditEditCopyData from './AuditEditCopyData';

const toastPosition = {
	position: 'absolute',
	top: 10,
	right: 10,
	zIndex: 1,
};

let defaultEditStructure = false;
function AuditEdit({audit, section}) {
	const history = useHistory();

	const [editStructure, setEditStructure] = useState(defaultEditStructure);

	const [saveAudit] = useMutation(SAVE_AUDIT);

	const {isBlocked} = useSubmitBlock();

	const [showSavedOK, setShowSavedOK] = useState(false)

	return <div className="FullScreenTabs">
		<Formik
			initialValues={getInitialValues(audit)}
			enableReinitialize
			validationSchema={auditSchema}
			onSubmit={async (values) => {

				values = prepareSaveValues(auditSchema.cast(values));

				try {
					await saveAudit({variables: {audit: values}});

					setShowSavedOK(true);
				} catch (e) {
					alert(e.message); // TODO better
				}
			}}
		>
			{form => {
				return <>
					<div>
						<Row>
							<Col md={5}>
								<strong>{form.values.name}</strong>
							</Col>
							<Col md={2}>
								<Button
									type="submit"
									disabled={!form.dirty || form.isSubmitting || isBlocked || !form.isValid}
									style={{cursor: isBlocked ? 'not-allowed' : undefined}}
									onClick={form.submitForm}
									block
									variant={form.isValid ? undefined : 'danger'}
									title={form.isValid ? undefined : `Can't save due to invalid fields`}
								>
									<ButtonProgress working={form.isSubmitting} />
									Save
								</Button>
							</Col>
							<Col md={{offset: 2}}>
								<Form.Check type="switch" label="Edit Structure" checked={editStructure} onChange={() => setEditStructure(!editStructure) || (defaultEditStructure = !editStructure)} id="EditStructureToggle" />
							</Col>
						</Row>
						<div style={toastPosition}>
							<Toast
								show={showSavedOK}
								onClose={() => setShowSavedOK(false)}
								delay={5000}
								autohide={true}
								className="bg-success"
							>
								<Toast.Header>
									<strong className="mr-auto">Save successful</strong>
								</Toast.Header>
							</Toast>
						</div>
					</div>
					<Tabs
						transition={false}
						mountOnEnter
						unmountOnExit
						activeKey={section}
						defaultActiveKey="main"
						onSelect={section => history.replace(`/audits/${audit.id}/${section}`)}
					>
						<Tab eventKey="main" title="Main" tabClassName={getIn(form.errors, 'tariffRate') ? 'text-danger' : null}>
							<AuditEditMain auditID={audit.id} />
						</Tab>
						<Tab eventKey="baseline" title="Baseline" tabClassName={getIn(form.errors, 'baseline') ? 'text-danger' : null}>
							<AuditEditBaseline isStructureEdit={editStructure} />
						</Tab>
						<Tab eventKey="observations" title="Observations" tabClassName={getIn(form.errors, 'observation') ? 'text-danger' : null}>
							<AuditEditObservation isStructureEdit={editStructure} />
						</Tab>
						<Tab eventKey="energyAssessment" title="Assessment" tabClassName={getIn(form.errors, 'energyAssessment') ? 'text-danger' : null}>
							<AuditEditEnergyAssessment />
						</Tab>
						{editStructure && <Tab eventKey="devices" title="Devices" tabClassName={getIn(form.errors, 'devices') ? 'text-danger' : null}>
							<AuditEditDevices isStructureEdit={editStructure} />
						</Tab>}
						{!editStructure && <Tab eventKey="usageCostsSavings" title="Usage / Costs / Savings">
							<AuditEditUsageCostsSavings />
						</Tab>}
						<Tab eventKey="possibleKPI" title="KPI's" tabClassName={getIn(form.errors, 'possibleKPI') ? 'text-danger' : null}>
							<AuditEditPossibleKPI isStructureEdit={editStructure} />
						</Tab>
						{!editStructure && <Tab eventKey="electricityBills" title="Electricity Bills" tabClassName={getIn(form.errors, 'electricityBills') ? 'text-danger' : null}>
							<ElectricityBills />
						</Tab>}
						<Tab eventKey="report" title="Report" tabClassName={getIn(form.errors, 'report') ? 'text-danger' : null}>
							<AuditEditReport auditID={audit.id} isStructureEdit={editStructure} />
						</Tab>
						{editStructure && <Tab eventKey="copyData" title="Copy Data">
							<AuditEditCopyData />
						</Tab>}
					</Tabs>
				</>;
			}}
		</Formik>
	</div>;
}

function initEditString(value) {
	return (value === null || value === undefined) ? '' : value;
}

export function initBaseline(baseline) {
	return baseline?.map(bld => {
		return {
			...bld,
			list: bld.list?.join('\n'),
			answer: initEditString(bld.answer),
		};
	});
}

export function initObservation(observation) {
	return observation?.map(obsg => {
		return {
			...obsg,
			observations: obsg.observations?.map(obs => {
				return {
					...obs,
					list: obs.list?.join('\n'),
					hint: initEditString(obs.hint),
					observation: initEditString(obs.observation),
					comment: initEditString(obs.comment),
				};
			}),
		};
	});
}

export function initPossibleKPI(possibleKPI) {
	return possibleKPI?.map(KPI => {
		return {
			...KPI,
			relevant: initEditString(KPI.relevant),
			measure: initEditString(KPI.measure),
		};
	});
}

export function initDevices(devices) {
	return devices?.map(dev => {
		return {
			...dev,
			wh: initEditString(dev.wh),
			opportunities: initEditString(dev.opportunities),
		};
	});
}

const getInitialValues = moize(audit => {
	return {
		...audit,
		auditDate: initEditString(audit.auditDate),
		project: initEditString(audit.project),
		client: initEditString(audit.client),
		contact: initEditString(audit.contact),
		tariffName: initEditString(audit.tariffName),
		tariffRate: initEditString(audit.tariffRate),
		author: initEditString(audit.author),
		checker: initEditString(audit.checker),
		emissionsFactor: initEditString(audit.emissionsFactor),
		baseline: initBaseline(audit.baseline),
		observation: initObservation(audit.observation),
		devices: initDevices(audit.devices),
		energyAssessment: audit.energyAssessment?.map(ea => {
			return {
				...ea,
				reference: initEditString(ea.reference),
				area: initEditString(ea.area),
				deviceType: initEditString(ea.deviceType),
				device: initEditString(ea.device),
				remarks: initEditString(ea.remarks),
				quantity: initEditString(ea.quantity),
				wh: initEditString(ea.wh),
				opDaysWk: initEditString(ea.opDaysWk),
				hrsDay: initEditString(ea.hrsDay),
				opportunities: initEditString(ea.opportunities),
				newWh: initEditString(ea.newWh),
			};
		}),
		possibleKPI: initPossibleKPI(audit.possibleKPI),
		electricityBills: audit.electricityBills?.map(bill => {
			return {
				...bill,
				months: bill.months.map(month => {
					return {
						...month,
						kWh: initEditString(month.kWh),
						cost: initEditString(month.cost),
					};
				}),
			};
		}),
	};
});

const cleanString = v => (v === null || v === undefined || v === '' || String(v).trim() === '') ? null : String(v).trim();
const cleanEmptyValue = (v, ov) => (ov === null || ov === undefined || ov === '' || String(ov).trim() === '') ? null : v;

const betterString = yup.string().transform(cleanString);
const betterDate = yup.date().transform(cleanEmptyValue);

const editType = betterString.oneOf(EDIT_TYPES);

const listValidator = yup.mixed().when('type', {
	is: EDIT_TYPE_LIST_VALUE,
	then: yup.array(betterString.required())
		.min(2)
		.required()
		.label('List Entries')
		.transform((_, value) => value ? String(value).split(/[\r\n]+/g).filter(x => x !== '') : []),
	else: yup.mixed().transform(() => null),
});

const auditSchema = yup.object({
	id: betterString.nullable().label('ID'),
	name: betterString.required().label('Name'),
	auditDate: betterDate.nullable().label('Audit Date'),
	tariffName: betterString.nullable().label('Tariff'),
	tariffRate: yup.number().nullable().transform(cleanEmptyValue).label('Tariff Rate'),
	client: betterString.nullable().label('Client'),
	contact: betterString.nullable().label('Contact'),
	author: betterString.nullable().label('Author'),
	checker: betterString.nullable().label('Checker'),
	emissionsFactor: yup.number().nullable().transform(cleanEmptyValue).label('Emissions Factor'),
	baseline: yup.array(yup.object({
		question: betterString.required().label('Question'),
		answer: betterString.nullable().label('Answer'),
		type: editType.required().label('Type'),
		list: listValidator.clone(),
	})).label('Baseline'),
	observation: yup.array(yup.object({
		name: betterString.required().label('Name'),
		observations: yup.array(yup.object({
			issue: betterString.required().label('Issue'),
			hint: betterString.nullable().label('Hint'),
			observation: betterString.nullable().label('Observation'),
			type: editType.required().label('Type'),
			list: listValidator.clone(),
			comment: betterString.nullable().label('Comment'),
		})).label('Baseline'),
	})).label('Observation'),
	devices: yup.array(yup.object({
		type: betterString.required().label('Type'),
		name: betterString.required().label('Name'),
		wh: yup.number().nullable().transform(cleanEmptyValue).label('Wh'),
		opportunities: betterString.nullable().label('Opportunities'),
	})).label('Baseline'),
	energyAssessment: yup.array(yup.object({
		reference: betterString.nullable().label('Building Reference'),
		area: betterString.required().label('Area'),
		deviceType: betterString.required().label('Device Type'),
		device: betterString.nullable().label('Device'),
		remarks: betterString.nullable().label('Remarks'),
		quantity: yup.number().integer().positive().nullable().transform(cleanEmptyValue).label('Quantity'),
		wh: yup.number().positive().nullable().transform(cleanEmptyValue).label('Avg Wh'),
		opDaysWk: yup.number().positive().nullable().transform(cleanEmptyValue).label('Days / Wk'),
		hrsDay: yup.number().positive().nullable().transform(cleanEmptyValue).label('Hrs / Day'),
		opportunities: betterString.nullable().label('opportunities'),
		newWh: yup.number().positive().nullable().transform(cleanEmptyValue).label('New Wh'),
	})).label('Energy Assessment'),
	electricityBills: yup.array(yup.object({
		startDate: betterDate.required().label('Start'),
		months: yup.array(yup.object({
			kWh: yup.number().nullable().transform(cleanEmptyValue).label('kWh'),
			cost: yup.number().nullable().transform(cleanEmptyValue).label('Cost'),
		})),
	})).label('Electricity Bill'),
	report: yup.object({
		sections: yup.array(yup.object({
			title: betterString.required().label('Title'),
			elements: yup.array(yup.object({
				type: betterString.required().label('Type'),
			})),
		})).nullable(),
	}).label('Report'),
});

function prepareSaveValues(values) {
	values = _.cloneDeep(values);

	values.report?.sections?.forEach(section => {
		if (section?.elements?.length) {
			section.elements = section.elements.map(({type, ...attributes}) => {
				return {
					type,
					attributes: JSON.stringify(attributes),
				};
			});
		}
	});

	delete values.areas;
	delete values.deviceTypes;

	return values;
}

export function AuditEditContainer() {

	const {id, section} = useParams();

	const {loading, error, data} = useQuery(GET_AUDIT, {variables: {id}});

	return <LoadingWrapper loading={loading} error={error}>
		<SubmitBlock>
			<AuditEdit audit={data?.audit} section={section} />
		</SubmitBlock>
	</LoadingWrapper>;
}
