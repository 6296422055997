import {memo} from 'react';
import {DateField, TextField} from './EditFields';
import {FieldArray, getIn, useFormikContext} from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import TrashIcon from 'react-bootstrap-icons/dist/icons/trash';
import _ from 'lodash';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import moment from 'moment';

const defaultEntry = {
	startDate: '',
	months: _.range(0, 12).map(() => ({cost: '', kWh: ''})),
};

export default function ElectricityBills() {
	return <Container fluid className="h-100 overflow-auto flex-grow-1">
		<FieldArray
			name="electricityBills"
		>
			{({form, name, push, remove}) => {
				const rows = form.values.electricityBills || [];
				return <>
					{rows.map((values, index) => {
						return <TableRow
							key={index}
							index={index}
							prefix={`${name}[${index}]`}
							values={values}
							remove={remove}
						/>
					})}

					<Row className="mt-3">
						<Col md={2}>
							<Button onClick={() => push(defaultEntry)} block variant="outline-primary">Add More</Button>
						</Col>
					</Row>
					<Row className="mt-3 mb-5">
						<Col md={12}>
							<Alert variant="warning">Make sure all start months align when using multiple years</Alert>
						</Col>
					</Row>
				</>;
			}}
		</FieldArray>
	</Container>;
}

const TableRow = memo(({prefix, remove, index}) => {
	return (<>
		<Row className="mt-2">
			<Form.Label column md={1}>
				<strong>Start</strong>
			</Form.Label>
			<Col md={2}>
				<DateField name={`${prefix}.startDate`} />
			</Col>
			<Col md={4}>
				<ElectricityBillGrid prefix={prefix} />
			</Col>
			<Col md={{offset: 4, span: 1}}>
				<Button
					title="Remove"
					variant="outline-danger"
					size="sm"
					onClick={() => remove(index)}
					block
				>
					<TrashIcon />
				</Button>
			</Col>
		</Row>
		<Row>
			<Col>
				<hr />
			</Col>
		</Row>
	</>);
});

function ElectricityBillGrid({prefix}) {
	const {values} = useFormikContext();
	const {startDate, months} = getIn(values, prefix);

	const date = moment(startDate).date(1);
	if (!date.isValid()) {
		return null;
	}

	return (
		<Table size="sm" hover bordered>
			<thead>
			<tr>
				<th>Month</th>
				<th style={{width: 150}}>kWh</th>
				<th style={{width: 150}}>Cost</th>
			</tr>
			</thead>
			<tbody>
			{months.map((month, index) => {
				return (
					<tr key={index}>
						<td className="font-weight-bold">
							{date.clone().add(index, 'months').format('MMM YY')}
						</td>
						<td>
							<TextField name={`${prefix}.months[${index}].kWh`} />
						</td>
						<td>
							<TextField name={`${prefix}.months[${index}].cost`} />
						</td>
					</tr>
				);
			})}
			</tbody>
		</Table>
	);
}
