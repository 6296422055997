import {memo} from 'react';
import TableEdit, {TableEditRowActions} from './TableEdit';
import {SelectField, TextField} from './EditFields';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useFormikContext} from 'formik';
import moize from 'moize';

const defaultEntry = {type: '', name: '', wh: '', opportunities: ''};

export default function AuditEditDevices({isStructureEdit}) {
	return <TableEdit
		name="devices"
		rowComponent={DeviceRow}
		isStructureEdit={isStructureEdit}
		defaultEntry={defaultEntry}
		headerComponent={DeviceHeader}
	/>;
}

const DeviceHeader = memo(() => {
	return <Row>
		<Col md={2}><span className="font-weight-bold">Type</span></Col>
		<Col md={3}><span className="font-weight-bold">Name</span></Col>
		<Col md={2}><span className="font-weight-bold">Wh</span></Col>
		<Col md={4}><span className="font-weight-bold">Opportunities</span></Col>
		<Col md={1}><span className="font-weight-bold">Actions</span></Col>
	</Row>;
});

const getTypeOptions = moize.infinite(types => {
	return types?.map(({short, name}) => ({value: short, label: name})) || [];
});
const DeviceRow = memo(({prefix, move, remove, index, totalLength}) => {
	const {values: {deviceTypes}} = useFormikContext();

	return <Row className="mt-1">
		<Col md={2} className="d-flex"><div style={{minWidth: 5 + (10 * String(totalLength).length)}}>{index + 1}.</div><div className="w-100"><SelectField name={`${prefix}.type`} options={getTypeOptions(deviceTypes)} /></div></Col>
		<Col md={3}><TextField name={`${prefix}.name`} /></Col>
		<Col md={2}><TextField name={`${prefix}.wh`} /></Col>
		<Col md={4}><TextField name={`${prefix}.opportunities`} /></Col>
		<Col md={1}><TableEditRowActions remove={remove} index={index} totalLength={totalLength} move={move} /></Col>
	</Row>;
});
