import {createContext, useCallback, useContext, useState} from 'react';

const SubmitBlockContext = createContext(null);

const useSubmitBlock = () => {
	const [counter, setCounter] = useContext(SubmitBlockContext);

	const block = useCallback(() => setCounter(counter + 1), [setCounter, counter]);
	const unblock = useCallback(() => {
		if (!counter) {
			console.warn('Too many unblocks');
		}
		setCounter(counter - 1)
	}, [setCounter, counter]);

	return {
		block,
		unblock,
		isBlocked: counter > 0,
	};
};

export default useSubmitBlock;

export function SubmitBlock({children}) {
	const state = useState(0);
	return (
		<SubmitBlockContext.Provider value={state}>
			{children}
		</SubmitBlockContext.Provider>
	);
}
