
export default function LoadingWrapper({loading, error, children}) {
	if (loading) {
		return 'Loading...';
	}

	if (error) {
		if (error.graphQLErrors?.length) {
			return `${error.graphQLErrors[0].message}`;
		}
		return `${error}`;
	}

	return children;
}
